<template>
  <div class="table-responsive">
    <table v-if="list && list.length > 0">
      <thead>
        <tr>
          <th v-if="from!='home'">{{$t('block')}}</th>
          <th>{{ $t("extrinsicID") }}</th>
          <th v-if="from!='home'">{{$t('extrinsicHash')}}</th>
          <th>{{ $t("from") }}</th>
          <th>{{ $t("to") }}</th>
          <th>{{ $t("value") }}</th>
          <template v-if="from!='home'">
            <th>{{$t('result')}}</th>
            <th>{{$t('time')}}</th>
          </template>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(item, index) in list" :key="index">
          <td v-if="from!='home'">
            <router-link
              :to="'/block/'+getItem(item).block_id"
            >{{getItem(item).block_id}}</router-link>
          </td>
          <td v-if="getItem(item).extrinsic_id">
            <router-link
              :to=" '/extrinsic/' + getItem(item).extrinsic_id"
              >{{ getItem(item).extrinsic_id }}</router-link
            >
          </td>
          <td v-else>(pending)</td>
          <template v-if="from!='home'">
            <td v-if="getItem(item).extrinsic_hash">
              <router-link :to="'/extrinsic/0x'+getItem(item).extrinsic_hash">{{webUtil.formatStrByDot('0x'+getItem(item).extrinsic_hash)}}</router-link>
            </td>
            <td v-else>(pending)</td>
          </template>
          <td v-if="getItem(item).sender" class="set-min">
            <router-link
              :to="'/account/' +
                getItem(item).sender.attributes.address
              "
              >{{
                webUtil.formatStrByDot(getItem(item).sender.attributes.address)
              }}</router-link
            >
            <template v-if="self">
              <p v-if="getItem(item).sender.attributes.address==self" class="transfer-out">OUT</p>
              <p v-else class="transfer-in">IN</p>
            </template>
          </td>
          <td v-else>(pending)</td>
          <td v-if="getItem(item).destination">
            <router-link
              :to="'/account/' +
                getItem(item).destination.attributes.address
              "
              >{{
                webUtil.formatStrByDot(
                  getItem(item).destination.attributes.address
                )
              }}</router-link
            >
          </td>
          <td v-else>(pending)</td>
          <td>
            {{
              getItem(item).value
                ? webUtil.formatNumber(getItem(item).value, tokenDecimals)
                : "(pending)"
            }}
            {{ tokenSymbol }}
          </td>
          <template v-if="from!='home'">
            <td class="text-green" v-if="getItem(item).success">Success</td>
            <td class="text-orange" v-else>Fail</td>
            <td>
              {{ getItem(item).datetime?webUtil.timestampToDate(getItem(item).datetime):'(pending)' }}
            </td>
          </template>
        </tr>
      </tbody>
    </table>
    <div class="null" v-else>{{ $t("noInformation") }}</div>
    <Loading :loading="isLoading"/>
  </div>
</template>

<script>
import Loading from "./loading";
export default {
  props: ["list", "isLoading", "from","self"],
  components: {
    Loading,
  },
  methods: {
    getItem(item) {
      return item.attributes;
    },
  },
};
</script>