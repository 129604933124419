<template>
  <section class="home">
    <div class="banner">
      <div class="container">
        <img src="@/static/img/logo/white.png" class="banner-logo" />
        <Search id="homeSearch"/>
      </div>
    </div>
    <div class="container">
      <!-- 图表 -->
      <charts-list :height="latestBlockHeight" :time="latestTimeDiff"></charts-list>
      <ul class="row">
        <!-- Blocks -->
        <li class="col-md-6">
          <div class="card table-list">
            <router-link class="view-all" to="/blocks">{{$t('view_all')}}</router-link>
            <h3>{{$t('blocks')}}</h3>
            <block-table :list="blocks" from="home" :isLoading="blockLoading"/>
          </div>
        </li>
        <!-- Transactions -->
        <li class="col-md-6">
          <div class="card table-list">
            <router-link class="view-all" to="/transfers">{{$t('view_all')}}</router-link>
            <h3>{{$t('transfers')}}</h3>
            <transfer-table :from="'home'" :list="transactions" :isLoading="transLoading"></transfer-table>
          </div>
        </li>
      </ul>
    </div>
  </section>
</template>

<script>
import ChartsList from "base/charts-list";
import Search from "base/search";
import TransferTable from 'base/transfer-table'
import BlockTable from 'base/block-table';
export default {
  data() {
    return {
      latestTimeDiff: 0,
      timeInterval: null,
      blocks: null,
      transactions: null,
      transLoading: true,
      blockLoading: true,
      totalInterval:null,
    };
  },
  computed: {
    latestBlock() {
      return this.blocks && this.blocks.length > 0 ? this.blocks[0] : null;
    },
    latestBlockHeight() {
      return this.latestBlock ? this.latestBlock.id : 0;
    }
  },
  created() {
    this.init();
  },
  methods: {
    init(){
      this.getBlocks();
      this.getTransactions();
      this.getLatestTimeDiff();
      this.totalInterval = setInterval(() => {
        this.getBlocks();
        this.getTransactions();
      }, 4000);
    },
    getBlocks() {
      this.axios
        .get(this.domain + "v1/block?page[size]=10&page[number]=1")
        .then(res => {
          this.blocks = res.data.data;
          this.blockLoading = false;
        })
        .catch(() => {
          console.log("Get blocks failed");
          this.blocks = null;
          this.blockLoading = false;
        });
    },
    // 获取交易记录
    getTransactions() {
      this.axios
        .get(this.domain + "v1/balances/transfer?page[size]=10&page[number]=1")

        .then(res => {
          this.transactions = res.data.data;
          this.transLoading = false;
        })
        .catch(() => {
          console.log("Get transactions failed");
          this.transactions = null;
          this.transLoading = false;
        });
    },
    getLatestTimeDiff() {
      this.timeInterval = setInterval(() => {
        if (this.latestBlock) {
          this.latestTimeDiff = (
            (Date.now() -
              new Date(this.latestBlock.attributes.datetime).getTime()) /
            1000
          ).toFixed(0);
        }
      }, 1000);
    }
  },
  beforeRouteLeave(to, from, next) {
    window.clearInterval(this.timeInterval);
    window.clearInterval(this.totalInterval);
    next();
  },
  components: {
    ChartsList,
    TransferTable,
    Search,
    BlockTable
  },
};
</script>
<style scoped>
.table-responsive {
  min-height: 464px;
}
</style>

