<template>
  <div class="table-responsive">
    <table v-if="list && list.length > 0">
      <thead>
        <tr>
          <th>{{ $t("height") }}</th>
          <th>{{ $t("blockHash") }}</th>
          <th>{{ $t("txs") }}</th>
          <th v-if="from != 'home'">{{ $t("signedExtrinsics") }}</th>
          <th>{{ $t("blockTime") }}</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="item in list" :key="item.id">
          <td>
            <router-link :to="'/block/' + item.id">{{
              item.id
            }}</router-link>
          </td>
          <td>
            {{
              item.attributes.hash
                ? webUtil.stringFormat(item.attributes.hash)
                : "(pending)"
            }}
          </td>
          <td>
            {{
              item.attributes.count_extrinsics
                ? item.attributes.count_extrinsics
                : 0
            }}
          </td>
          <td v-if="from != 'home'">
            {{
              item.attributes.count_extrinsics_signed
                ? item.attributes.count_extrinsics_signed
                : 0
            }}
          </td>
          <td>
            {{
              item.attributes.datetime
                ? webUtil.timestampToDate(item.attributes.datetime)
                : "(pending)"
            }}
          </td>
        </tr>
      </tbody>
    </table>
    <div class="null" v-else>{{ $t("noInformation") }}</div>
    <loading :loading="isLoading"></loading>
  </div>
</template>

<script>
import loading from "./loading";
export default {
  props: ["list", "isLoading", "from"],
  components: {
    loading,
  },
  methods: {
    getItem(item) {
      return item.attributes;
    },
  },
};
</script>