<template>
  <ul class="recent-list row">
    <li class="col-md-4">
      <router-link to="/blocks" class="card">
        <h4>{{ $t("height") }}</h4>
        <h3>{{ height>0?webUtil.addCommas(height, 0):'-' }}</h3>
        <p class="text-grey">Last {{ time ? time : 0 }}s ago</p>
      </router-link>
    </li>
    <li class="col-md-4">
      <div class="card clearfix">
        <div class="chart price" ref="price"></div>
        <div class="info">
          <h4>MATH {{ $t("price") }}</h4>
          <h3>$ {{ tokenStatus.price }}</h3>
          <p class="text-green" v-if="tokenStatus.token.percentChange >= 0">
            +{{ tokenStatus.token.percentChange }}%
          </p>
          <p class="text-red" v-else>{{ tokenStatus.token.percentChange }}%</p>
        </div>
      </div>
    </li>
    <li class="col-md-4">
      <div class="card clearfix">
        <div class="chart" ref="circulate"></div>
        <div class="info">
          <h4>MATH {{ $t("totalCirculating") }}</h4>
          <h3>
            {{ webUtil.addCommas(totalCirculating, tokenStatus.token.point) }}
          </h3>
          <p class="text-grey" v-if="totalSupply > 0">
            {{ $t("ofTotalSupply") }} : {{ circulateRate }}%
          </p>
          <p class="text-grey" v-else>{{ $t("ofTotalSupply") }}: 0.00%</p>
        </div>
      </div>
    </li>
  </ul>
</template>

<script>
import echarts from "static/js/echarts.min.js";
export default {
  props: ["height", "time"],
  data() {
    return {
      tokenStatus: {
        price: "0.00",
        token: {
          name: "",
          decimals: "0",
          point: 18,
          percentChange: "0",
        },
      },
      priceOption: null,
      totalCirculating: "0",
      totalSupply: "0",
      circulateRate: "",
    };
  },
  mounted() {
    // MATH Price图表
    this.priceSatatus();
    // 代币抵押状态
    this.stakeStatus();
  },
  methods: {
    // MATH Price图表
    priceSatatus() {
      this.axios
        .get(
          this.marketApi +
            "pricePoint?tokenID=2370&type=5min&limit=288&baseCoin=USD"
        )
        .then((res) => {
          if (res.data.success && res.data.data.price) {
            var dataList = res.data.data.price.reverse();
            let USDRmbPrice = res.data.data.baseCoin.rmb;

            // 折线图x轴，y轴数据
            let timeList = [],
              priceList = [],
              minPrice = 0,
              maxPrice = 0;
            if (dataList && dataList.length) {
              timeList = dataList.map((v) => v.time);
              priceList = dataList.map((v) =>
                this.webUtil.BN(v.last2Rmb).div(USDRmbPrice).toFixed(4)
              );

              minPrice = Math.min.apply(Math, priceList);
              maxPrice = Math.max.apply(Math, priceList);

              // MATH Price图表参数
              let priceOption = {
                grid: {
                  left: 6,
                  top: 6,
                  right: 6,
                  bottom: 6,
                },
                tooltip: {
                  trigger: "axis",
                },
                xAxis: [
                  {
                    show: false,
                    data: timeList,
                  },
                ],
                yAxis: [
                  {
                    show: false,
                    max: maxPrice,
                    min: minPrice,
                  },
                ],
                series: [
                  {
                    type: "line",
                    showSymbol: false,
                    color: "#007aff",
                    data: priceList,
                    areaStyle: {
                      color: "#e6f2ff",
                    },
                  },
                ],
              };

              // 画表
              this.drawChart("price", priceOption);
            }
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    stakeStatus() {
      this.axios
        .get("https://api.maiziqianbao.net/apiVpos/tokenStatus?tokenID=2370")
        .then((res) => {
          if (res.data.success) {
            this.tokenStatus = res.data.data;
            let decimals = Math.pow(10, this.tokenStatus.token.decimals);
            let circulate = this.webUtil
              .BN(this.tokenStatus.totalCirculating)
              .div(decimals)
              .toNumber();
            let supply = this.webUtil
              .BN(this.tokenStatus.totalSupply)
              .div(decimals)
              .toNumber();
            let unCirculate = this.webUtil
              .BN(supply)
              .minus(circulate)
              .toNumber();

            this.totalCirculating = circulate;
            this.totalSupply = supply;

            this.circulateRate = this.webUtil
              .BN(circulate)
              .div(supply)
              .multipliedBy(100)
              .toFixed(2);

            // MATH Total Circulating图表信息
            let circulateOption = {
              tooltip: {
                trigger: "item",
                formatter: "{b}<br/>{c} ({d}%)",
              },
              color: ["#007aff", "#e6f2ff"],
              series: [
                {
                  name: "MATH " + this.$t("totalCirculating"),
                  type: "pie",
                  radius: ["60%", "80%"],
                  hoverAnimation: false,
                  labelLine: {
                    normal: {
                      show: false,
                    },
                  },
                  data: [
                    {
                      value: circulate,
                      name: this.$t("totalCirculating"),
                      itemStyle: {
                        emphasis: {
                          color: "#007aff",
                        },
                      },
                    },
                    {
                      value:
                        circulate == 0 && unCirculate == 0 ? 1 : unCirculate,
                      name: this.$t("unMined"),
                      itemStyle: {
                        emphasis: {
                          color: "#e6f2ff",
                        },
                      },
                    },
                  ],
                },
              ],
            };
            this.drawChart("circulate", circulateOption);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    drawChart(id, option) {
      // 初始化echarts实例
      if (this.$refs[id]) {
        let myChart = echarts.init(this.$refs[id]);
        // 绘制图表
        myChart.setOption(option);
      }
    },
  },
  watch: {
    langLocale() {
      // MATH Price图表
      this.priceSatatus();
      // 代币抵押状态
      this.stakeStatus();
    },
  },
};
</script>

<style>
.recent-list .card {
  padding: 24px 16px;
  display: block;
  color: #000;
  height: 126px;
}

.recent-list p {
  font-size: 12px;
  font-weight: 500;
  font-family: Gotham-Medium;
  line-height: 1.33;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.recent-list .chart {
  float: right;
  width: 80px;
  height: 80px;
}

.recent-list .price {
  width: 200px;
}
.recent-list h4 {
  font-size: 16px;
  font-weight: 400;
  margin-bottom: 4px;
  white-space: nowrap;
}

.recent-list h3 {
  font-size: 24px;
  line-height: 1.33;
  font-weight: 400;
  margin-bottom: 4px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.text-red {
  color: #ef5656;
}
@media (max-width: 1200px) {
  .recent-list h4 {
    font-size: 14px;
    margin-bottom: 8px;
  }

  .recent-list h3 {
    font-size: 20px;
  }
}
</style>
